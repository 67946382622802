"use client";

import { SessionProvider as NextAuthSessionProvider } from "next-auth/react";
import React, { ReactNode } from "react";

export default function SessionProvider(props: { children: ReactNode }) {
  return (
    <NextAuthSessionProvider refetchInterval={60 * 30}>
      {props.children}
    </NextAuthSessionProvider>
  );
}
