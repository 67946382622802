"use client";

import { outpostClientAPI } from "@dashboard/fetch/client";
import { signOut, useSession } from "next-auth/react";
import { ReactNode, useCallback, useLayoutEffect } from "react";

export function UserValidityCheck(props: { children: ReactNode }) {
  const { data: session, status } = useSession();
  const checkIfUserValid = useCallback(async () => {
    try {
      const res = await outpostClientAPI.get({
        path: `/user`,
      });

      if (
        status == "authenticated" &&
        (res?.status == 404 || res?.status == 401)
      ) {
        signOut();
      }
    } catch (err: any) {}
  }, [props]);

  useLayoutEffect(() => {
    if (session?.user.userId) {
      checkIfUserValid();
    }
  }, [props, checkIfUserValid]);

  return <>{props.children}</>;
}
